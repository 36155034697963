@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,500,700&display=swap"); /* https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.elevation-level-1 {
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

.elevation-level-2 {
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
}

.elevation-level-3 {
  -webkit-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
}

.elevation-level-4 {
  -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
}

.elevation-level-5 {
  -webkit-box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.5);
}

.elevation-level-6 {
  -webkit-box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.5);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    z-index: -1;
  }
  100% {
    opacity: 1;
    z-index: 999;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    z-index: 999;
  }
  100% {
    opacity: 0;
    z-index: -999;
  }
}
#auth-widget {
  position: absolute;
  right: 0px;
  background: rgba(220, 220, 220, 0.7);
  padding: 15px;
}
#auth-widget p {
  margin: 0px;
}

.custom-slider-arrow {
  background-image: url(../../../images/arrow.jpg);
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 999;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.custom-slider-arrow.next.vertical {
  left: calc(50% - 20px);
  bottom: 20px;
  transform: rotate(-90deg);
}
.custom-slider-arrow.next:not(.vertical) {
  top: calc(50% - 20px);
  right: 20px;
  transform: rotate(180deg);
}
.custom-slider-arrow.prev.vertical {
  left: calc(50% - 20px);
  top: 20px;
  transform: rotate(90deg);
}
.custom-slider-arrow.prev:not(.vertical) {
  top: calc(50% - 20px);
  left: 20px;
}

.product-image {
  max-height: 100%;
  max-width: 100%;
}

#footer {
  width: 100%;
  border-top: 1px solid #d9e4f0;
  background-color: #2a5379;
}
#footer #footer-content {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 15px 5vw 15px 5vw;
}
#footer #footer-content > * {
  align-self: center;
}

#navbar {
  transition: all 1s;
}
#navbar #navbar-logo {
  transition: all 1s;
}
#navbar.shrink {
  padding-top: 8px;
  padding-bottom: 8px;
}
#navbar.shrink #navbar-logo {
  max-width: 75%;
}

#social-media {
  justify-self: end;
}
#social-media > a {
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}
#social-media > a > div {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: white !important;
}

#navbar {
  position: fixed;
  z-index: 999;
  width: 100%;
  border-radius: 2px;
  background-color: white;
  padding: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 15px;
  border-radius: 2px;
  max-height: 105px;
}
#navbar hr {
  border-top: 1px solid rgb(100, 100, 100);
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 75%;
  margin: 0px;
}
#navbar #navbar-logo {
  max-height: 45px;
  max-width: 100%;
  width: auto;
  margin-bottom: 10px;
}
#navbar #navbar-main {
  justify-self: center;
  align-self: center;
  height: 100%;
}
#navbar #navbar-extra-info {
  padding-left: 10px;
  justify-self: center;
  align-self: center;
  min-width: 275px;
}
#navbar #navbar-extra-info > p {
  font-size: 20px;
  line-height: 1rem;
}
#navbar #navbar-extra-info a:hover {
  color: #2a5379;
}
#navbar #nav-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  max-width: 75%;
  margin-top: 5px;
}
#navbar #nav-items .navlink {
  transition: all 0.5s !important;
  line-height: 1rem;
}
#navbar #nav-items .navlink:after {
  display: block;
  content: "";
  border-bottom: solid 1px #2a5379;
  border-radius: 50px;
  transform: scaleX(0);
  transition: transform 150ms ease-in-out;
}
#navbar #nav-items .navlink:hover, #navbar #nav-items .navlink.selected {
  color: #2a5379;
}
#navbar #nav-items .navlink:hover:after, #navbar #nav-items .navlink.selected:after {
  transform: scaleX(1);
}

#landing {
  position: relative;
  overflow: hidden !important;
}

#landing:not(.mobile) {
  padding-top: 105px;
}
#landing:not(.mobile) #landing-content-container h1 {
  background: rgba(0, 0, 0, 0.55);
  background: radial-gradient(circle, rgba(0, 0, 0, 0.75) 15%, rgba(0, 0, 0, 0.4) 75%, rgba(0, 0, 0, 0) 100%);
}
#landing:not(.mobile),
#landing:not(.mobile) #landing-background,
#landing:not(.mobile) #landing-background-overlay,
#landing:not(.mobile) #landing-content-container,
#landing:not(.mobile) #landing-background-content,
#landing:not(.mobile) #landing-background-content,
#landing:not(.mobile) .slick-slider,
#landing:not(.mobile) .slick-list,
#landing:not(.mobile) .slick-track,
#landing:not(.mobile) .slick-slide {
  height: 100vh;
}
#landing:not(.mobile) #landing-content-container,
#landing:not(.mobile) #landing-background-overlay {
  top: 105px;
}

#landing.mobile {
  padding-top: 0px;
}
#landing.mobile #nav-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  height: 25%;
}
#landing.mobile #landing-content-container {
  background: rgba(0, 0, 0, 0.55);
  background: radial-gradient(circle, rgba(0, 0, 0, 0.75) 15%, rgba(0, 0, 0, 0.4) 75%, rgba(0, 0, 0, 0) 100%);
}
#landing.mobile,
#landing.mobile #landing-background,
#landing.mobile #landing-background-overlay,
#landing.mobile #landing-content-container,
#landing.mobile #landing-background-content,
#landing.mobile #landing-background-content,
#landing.mobile .slick-slider,
#landing.mobile .slick-list,
#landing.mobile .slick-track,
#landing.mobile .slick-slide {
  height: 100vh;
  top: 0px;
}
#landing.mobile #mobile-nav {
  width: 100%;
  font-size: 2rem;
}
#landing.mobile #mobile-nav #nav-items {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}
#landing.mobile #mobile-nav .navlink {
  margin-top: 10px;
  margin-bottom: 10px;
  color: white !important;
}

#landing-background-content .slick-slide > div {
  position: relative;
}
#landing-background-content .slick-slide > div > img {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: auto !important;
  min-width: 100vw;
}

#landing-background #landing-background-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

#landing-content-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
}
#landing-content-container h1 {
  width: 100%;
  color: white;
}

#logo {
  display: block;
  border-radius: 5px;
  padding: 15px;
  max-width: 20rem;
}

#memorial {
  padding: 5vw 5vw 0px 5vw;
}
#memorial #young-marino {
  max-width: 600px;
  width: 90%;
  margin: 0px auto 15px auto;
  border-radius: 5px;
}
#memorial .section-description {
  text-align: center;
  margin-bottom: 15px;
}
#memorial .section-description a {
  color: #376d9f;
  font-weight: bold;
  text-decoration: underline;
}
#memorial .section-description a:hover {
  color: #4987c0;
}

#info {
  padding: 5vw 5vw 0px 5vw;
}
#info .section-description {
  text-align: center;
  margin-bottom: 15px;
}
#info .highlighted {
  font-weight: bold;
  color: rgb(91, 155, 213);
}

#brands {
  padding: 5vw 5vw 0px 5vw;
}
#brands .section-description {
  margin-bottom: 30px;
}

#suppliers-container {
  display: grid;
  grid-auto-rows: 200px;
  grid-gap: 30px;
  width: 100%;
}

.supplier {
  border: rgb(115, 115, 115) 1px solid;
  overflow: hidden;
  background-color: white;
}
.supplier > div {
  height: 100%;
  width: 100%;
}
.supplier.expanded {
  position: relative;
  z-index: 900;
}
.supplier.collapsed {
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.navlink.visible {
  animation: fadeIn 1s;
  display: inline !important;
}
.navlink.invisible {
  animation: fadeOut 1s;
  display: none !important;
}
.navlink .view-supplier {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.supplier-content {
  position: relative;
  padding: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.supplier-content.visible {
  animation: fadeIn 1s;
  display: block !important;
}
.supplier-content.invisible {
  animation: fadeOut 1s;
  display: none !important;
}
.supplier-content .close-icon-svg {
  position: absolute;
  top: 30px;
  right: 30px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.supplier-content .close-icon-svg svg {
  position: absolute;
  top: 0px;
  left: 0px;
}

.supplier-content-header {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.supplier-content-header .supplier-content-header-logo {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.supplier-content-header .supplier-content-header-logo .supplier-image {
  max-width: 100%;
  max-height: 100%;
}

.supplier-image {
  max-height: 100%;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  display: inline-block;
}

.supplier-website {
  width: 50%;
  text-align: center;
  font-weight: 400;
}
.supplier-website > a {
  border-radius: 3px;
  transition: all 0.5s;
  padding: 5px;
}
.supplier-website > a:hover {
  background-color: #2a5379;
  color: white;
}

.supplier-content-main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 75%;
  padding-top: 30px;
}
.supplier-content-main .supplier-description {
  font-style: italic;
  width: 50%;
  font-size: initial;
  font-weight: bold;
  padding-right: 15px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}
.supplier-content-main .supplier-product-carousel-container {
  width: 50%;
  padding-left: 15px;
}
.supplier-content-main .supplier-product-carousel-container,
.supplier-content-main .supplier-product-carousel-container .slick-slider,
.supplier-content-main .supplier-product-carousel-container .slick-list,
.supplier-content-main .supplier-product-carousel-container .slick-track,
.supplier-content-main .supplier-product-carousel-container .slick-slide,
.supplier-content-main .supplier-product-carousel-container .slick-slide > div,
.supplier-content-main .supplier-product-carousel-container .supplier-product-image {
  height: 100%;
}
.supplier-content-main .supplier-product-carousel-container .slick-slide > div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.supplier-content-main .supplier-product-carousel-container .supplier-product-image {
  max-height: 100%;
  height: initial !important;
  width: initial !important;
}

#about {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5vw 5vw 5vw 5vw;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
#about .section-description {
  text-align: center;
  margin-bottom: 30px;
}
#about .marino {
  width: 50%;
  min-width: 350px;
  max-width: 600px;
  border-radius: 3px;
}

#contact {
  border-top: 1px solid #6e7271;
  background-color: #2a5379;
  padding: 15px 5vw 15px 5vw;
}
#contact h1 {
  display: block;
  text-align: center;
  margin-top: 0px;
  font-size: 1.5rem;
}
#contact * {
  color: white;
  background-color: #2a5379;
}

#hours {
  grid-area: hours;
  display: grid;
  grid-template-columns: 5fr 2fr 1fr 2fr;
  padding: 15px;
  width: fit-content;
  height: fit-content;
}
#hours > span {
  width: 100%;
}
#hours > span:nth-child(4n+1) {
  margin: 0px 15px 0px 0px;
  padding: 0px 5px 0px 5px;
}
#hours > span:nth-child(4n+1).today {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
#hours > span:nth-child(4n+2) {
  text-align: right;
}
#hours > span:nth-child(4n+3) {
  text-align: center;
}
#hours > span:nth-child(4n).today {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
#hours > span.today {
  background-color: white;
  color: #2a5379;
}

#email-us {
  grid-area: email;
}
#email-us h3 {
  margin: 0px;
}
#email-us h3 {
  margin-bottom: 7px;
}
#email-us #contact-form {
  display: flex;
  flex-direction: column;
}
#email-us #contact-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bfbfbf;
  font-size: 0.8rem;
}
#email-us #contact-form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #bfbfbf;
  font-size: 0.8rem;
}
#email-us #contact-form :-ms-input-placeholder {
  /* IE 10+ */
  color: #bfbfbf;
  font-size: 0.8rem;
}
#email-us #contact-form :-moz-placeholder {
  /* Firefox 18- */
  color: #bfbfbf;
  font-size: 0.8rem;
}
#email-us #contact-form > input:first-child {
  margin-top: 0px;
}
#email-us #contact-form input,
#email-us #contact-form textarea {
  margin: 8px 0px 8px 0px;
  border: 1px solid white;
  padding: 10px !important;
  font-size: 1em;
}
#email-us #contact-form input[type=submit],
#email-us #contact-form textarea[type=submit] {
  font-size: 0.8rem;
}
#email-us #contact-form input[type=submit]:not(.disabled):hover,
#email-us #contact-form textarea[type=submit]:not(.disabled):hover {
  cursor: pointer;
  background-color: white;
  color: #2a5379;
}
#email-us #contact-form input[type=submit].disabled,
#email-us #contact-form textarea[type=submit].disabled {
  color: #bfbfbf;
}
#email-us #contact-form input:focus,
#email-us #contact-form textarea:focus {
  outline-color: #9da6b0;
  outline-width: 3px;
}

#visit-us {
  grid-area: visit;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  margin-left: 15px;
}

#hours-message {
  grid-area: hours-message;
}

#email-message {
  grid-area: email-message;
}

#visit-message {
  grid-area: visit-message;
}

#hours-message,
#email-message,
#visit-message,
#hours,
#email,
#visit {
  justify-self: center;
  align-self: center;
  text-align: center;
  font-size: 1rem !important;
}

#submit-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.section-title {
  margin-bottom: 15px;
  font-weight: bold;
}

#main {
  position: relative;
}

@media screen and (max-width: 767px) {
  html {
    font-size: 16px;
  }
  .supplier-content * {
    font-size: 14px !important;
    line-height: initial !important;
    font-weight: normal !important;
  }
  .supplier-content-header-logo {
    width: auto !important;
    max-width: 100% !important;
    margin: auto;
  }
  .supplier-content-header-logo .supplier-image {
    max-height: 75px !important;
  }
  .supplier-website {
    margin: auto;
    width: 100% !important;
    padding: 15px;
    margin-top: 15px;
  }
  .supplier-website > a {
    font-size: 24px !important;
  }
  .supplier-content-header {
    width: 100%;
    flex-direction: column;
    height: auto;
    text-align: center;
  }
  .supplier-content-main {
    width: 100%;
    display: block;
    height: auto;
    padding-top: 0px;
  }
  .supplier-content-main .supplier-description {
    width: 100%;
  }
  .supplier-content-main .supplier-product-carousel-container {
    margin: auto;
    max-height: 150px !important;
    padding-top: 15px;
  }
  .supplier-content-main .supplier-product-carousel-container .slick-slide > div {
    display: block;
  }
  #contact {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  #contact-form input[type=submit] {
    width: 50% !important;
  }
  #footer {
    flex-direction: column;
  }
  #contact-panels {
    display: flex;
    flex-direction: column;
  }
  #contact-panels > * {
    width: 100%;
    justify-self: center;
  }
  #email-message,
  #visit-message {
    border-top: 1px solid #d9e4f0;
    padding-top: 15px;
  }
  #hours {
    padding: 15px 0px 15px 0px;
  }
  #hours-message,
  #email-message,
  #visit-message {
    font-size: 1.25rem !important;
  }
}
@media screen and (max-width: 359px) {
  #suppliers-container {
    grid-template-columns: 1fr;
  }
  #suppliers-container .supplier.expanded {
    grid-column: span 1;
    grid-row: span 2;
  }
  .supplier-content-header-logo {
    width: 60% !important;
  }
  .supplier-content-main .supplier-product-carousel-container {
    max-height: 100px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 539px) {
  #suppliers-container {
    grid-template-columns: 1fr;
  }
  #suppliers-container .supplier.expanded {
    grid-column: span 1;
    grid-row: span 2;
  }
  .supplier-content-header-logo {
    width: 60% !important;
  }
  .supplier-content-main .supplier-product-carousel-container {
    max-height: 125px !important;
  }
}
@media screen and (min-width: 540px) and (max-width: 767px) {
  #suppliers-container {
    grid-template-columns: 1fr 1fr;
  }
  #suppliers-container .supplier.expanded {
    grid-column: span 2;
    grid-row: span 2;
  }
  #landing-content-container h1 {
    padding-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 24px;
  }
  #landing-content-container h1 {
    padding: 30px;
  }
  #contact * {
    font-size: 0.8rem;
  }
  #about-content-container {
    width: 80%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #suppliers-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #suppliers-container .supplier.expanded {
    grid-column: span 3;
    grid-row: span 2;
  }
  #contact-form input[type=submit] {
    width: 50%;
  }
  #contact-panels {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "email-message visit" "hours visit";
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  #suppliers-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #suppliers-container .supplier.expanded {
    grid-column: span 3;
    grid-row: span 2;
  }
  #contact-form input[type=submit] {
    width: 50%;
  }
  #contact-panels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas: "email-message visit visit" "hours visit visit";
  }
}
@media screen and (min-width: 1200px) {
  #suppliers-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  #suppliers-container .supplier.expanded {
    grid-column: span 2;
    grid-row: span 2;
  }
  #contact-form input[type=submit] {
    width: 50%;
  }
  #contact-panels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas: "email-message visit visit" "hours visit visit";
    justify-items: stretch;
    justify-content: stretch;
  }
}
body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(55, 55, 55) !important !important;
  font-weight: 300;
  color: #6e7271;
  min-width: 320px;
}
body * {
  font-family: inherit;
  z-index: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  text-align: center;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: #6e7271;
}
a:visited {
  color: inherit;
}
a.selected {
  color: inherit;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}