@media screen and (min-width: map-get($map: $screen-sizes, $key: "md")) and (max-width: (map-get($map: $screen-sizes, $key: "lg") - 1px)) {
    #suppliers-container {
        grid-template-columns: 1fr 1fr 1fr;

        .supplier.expanded {
            grid-column: span 3;
            grid-row: span 2;
        }
    }

    #contact-form {
        input[type="submit"] {
            width: 50%;
        }
    }

    #contact-panels {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        grid-template-areas:
            "email-message visit"
            "hours visit";
    }
}
