#about {
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 5vw 5vw 5vw 5vw;
  justify-content: center;
  align-items: center;
  z-index: -1;

  .section-description {
    text-align: center;
    margin-bottom: 30px;
  }

  .marino {
    width: 50%;
    min-width: 350px;
    max-width: 600px;
    border-radius: 3px;
  }
}
