@media screen and (min-width: map-get($map: $screen-sizes, $key: "xl")) {
    #suppliers-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        .supplier.expanded {
            grid-column: span 2;
            grid-row: span 2;
        }
    }

    #contact-form {
        input[type="submit"] {
            width: 50%;
        }
    }

    #contact-panels {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: auto;
        grid-template-areas:
            "email-message visit visit"
            "hours visit visit";
        justify-items: stretch;
        justify-content: stretch;
    }
}
