#auth-widget {
    position: absolute;
    right: 0px;
    background: rgba(220, 220, 220, 0.7);
    padding: 15px;

    p {
        margin: 0px;
    }
}
