#memorial {
  padding: 5vw 5vw 0px 5vw;

  #young-marino {
    max-width: 600px;
    width: 90%;
    margin: 0px auto 15px auto;
    border-radius: $default-border-radius;
  }

  .section-description {
    text-align: center;
    margin-bottom: 15px;

    a {
      color: lighten($osso-colour-1, 10%);
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: lighten($osso-colour-1, 20%);
      }
    }
  }
}
