@media screen and (min-width: map-get($map: $screen-sizes, $key: "xs")) and (max-width: (map-get($map: $screen-sizes, $key: "sm") - 1px)) {
  #suppliers-container {
    grid-template-columns: 1fr;

    .supplier.expanded {
      grid-column: span 1;
      grid-row: span 2;
    }
  }

  .supplier-content-header-logo {
    width: 60% !important;
  }

  .supplier-content-main .supplier-product-carousel-container {
    max-height: 125px !important;
  }
}
