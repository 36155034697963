$default-colour: rgb(55, 55, 55) !important;

$osso-colour-1: #2a5379;
$osso-colour-2: #6e7271;
$osso-colour-3: #8ea7bd;
$osso-colour-4: #5c6672;
$osso-colour-5: #d9e4f0;

$default-border-radius: 5px;

$navbar-height: 105px;
$navbar-padding: 10px;
