#landing {
  position: relative;
  overflow: hidden !important;
}

#landing:not(.mobile) {
  padding-top: $navbar-height;

  #landing-content-container {
    h1 {
      @include landing-background;
    }
  }

  &,
  #landing-background,
  #landing-background-overlay,
  #landing-content-container,
  #landing-background-content,
  #landing-background-content,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100vh;
  }

  #landing-content-container,
  #landing-background-overlay {
    top: $navbar-height;
  }
}

#landing.mobile {
  padding-top: 0px;

  #nav-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    height: 25%;
  }

  #landing-content-container {
    @include landing-background;
  }

  &,
  #landing-background,
  #landing-background-overlay,
  #landing-content-container,
  #landing-background-content,
  #landing-background-content,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100vh;
    top: 0px;
  }

  #mobile-nav {
    width: 100%;
    font-size: 2rem;

    #nav-items {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
    }
    .navlink {
      margin-top: 10px;
      margin-bottom: 10px;
      color: white !important;
    }
  }
}

#landing-background-content {
  .slick-slide {
    & > div {
      position: relative;

      & > img {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: auto !important;
        min-width: 100vw;
      }
    }
  }
}

#landing-background {
  #landing-background-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
  }
}

#landing-content-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  min-width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  justify-content: center;

  h1 {
    width: 100%;
    color: white;
  }
}

#logo {
  display: block;
  border-radius: $default-border-radius;
  padding: 15px;
  max-width: 20rem;
}
