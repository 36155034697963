@media screen and (min-width: map-get($map: $screen-sizes, $key: "sm")) and (max-width: (map-get($map: $screen-sizes, $key: "md") - 1px)) {
  #suppliers-container {
    grid-template-columns: 1fr 1fr;

    .supplier.expanded {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  #landing-content-container {
    h1 {
      padding-top: 30px;
    }
  }
}
