#navbar {
  transition: all 1s;
  #navbar-logo {
    transition: all 1s;
  }

  &.shrink {
    padding-top: 8px;
    padding-bottom: 8px;
    #navbar-logo {
      max-width: 75%;
    }
  }
}
