@import "./landing";
@import "./memorial";
@import "./info";
@import "./suppliers";
@import "./about";
@import "./contact";

.section-title {
  margin-bottom: 15px;
  font-weight: bold;
}
