#contact {
    border-top: 1px solid $osso-colour-2;
    background-color: $osso-colour-1;
    padding: 15px 5vw 15px 5vw;

    h1 {
        display: block;
        text-align: center;
        margin-top: 0px;
        font-size: 1.5rem;
    }

    * {
        color: white;
        background-color: $osso-colour-1;
    }
}

#hours {
    grid-area: hours;
    display: grid;
    grid-template-columns: 5fr 2fr 1fr 2fr;

    padding: 15px;

    width: fit-content;
    height: fit-content;

    //Day Column
    & > span {
        width: 100%;

        &:nth-child(4n + 1) {
            margin: 0px 15px 0px 0px;
            padding: 0px 5px 0px 5px;

            &.today {
                border-top-left-radius: $default-border-radius;
                border-bottom-left-radius: $default-border-radius;
            }
        }

        //Open column
        &:nth-child(4n + 2) {
            text-align: right;
        }

        //Dash column
        &:nth-child(4n + 3) {
            text-align: center;
        }

        //Close column
        &:nth-child(4n) {
            &.today {
                border-top-right-radius: $default-border-radius;
                border-bottom-right-radius: $default-border-radius;
            }
        }

        &.today {
            background-color: white;
            color: $osso-colour-1;
        }
    }
}

#email-us {
    grid-area: email;

    h3 {
        margin: 0px;
    }

    h3 {
        margin-bottom: 7px;
    }

    #contact-form {
        display: flex;
        flex-direction: column;

        ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: darken(white, 25%);
            font-size: 0.8rem;
        }
        ::-moz-placeholder {
            /* Firefox 19+ */
            color: darken(white, 25%);
            font-size: 0.8rem;
        }
        :-ms-input-placeholder {
            /* IE 10+ */
            color: darken(white, 25%);
            font-size: 0.8rem;
        }
        :-moz-placeholder {
            /* Firefox 18- */
            color: darken(white, 25%);
            font-size: 0.8rem;
        }

        & > input:first-child {
            margin-top: 0px;
        }

        input,
        textarea {
            margin: 8px 0px 8px 0px;
            border: 1px solid white;
            padding: 10px !important;
            font-size: 1em;

            &[type="submit"] {
                font-size: 0.8rem;
                &:not(.disabled) {
                    &:hover {
                        cursor: pointer;
                        background-color: white;
                        color: $osso-colour-1;
                    }
                }

                &.disabled {
                    color: darken(white, 25%);
                }
            }

            &:focus {
                outline-color: lighten($osso-colour-4, 25%);
                outline-width: 3px;
            }
        }
    }
}

#visit-us {
    grid-area: visit;
    height: 100%;
    width: 100%;
    border-radius: $default-border-radius;
    margin-left: 15px;
}

#hours-message {
    grid-area: hours-message;
}

#email-message {
    grid-area: email-message;
}

#visit-message {
    grid-area: visit-message;
}

#hours-message,
#email-message,
#visit-message,
#hours,
#email,
#visit {
    justify-self: center;
    align-self: center;
    text-align: center;

    font-size: 1rem !important;
}

#submit-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
