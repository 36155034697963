#footer {
    width: 100%;
    border-top: 1px solid $osso-colour-5;
    background-color: $osso-colour-1;

    #footer-content {
        color: white;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 15px 5vw 15px 5vw;

        & > * {
            align-self: center;
        }
    }
}
