#brands {
  padding: 5vw 5vw 0px 5vw;

  .section-description {
    margin-bottom: 30px;
  }
}

#suppliers-container {
  display: grid;
  grid-auto-rows: 200px;
  grid-gap: 30px;
  width: 100%;
}

.supplier {
  border: rgb(115, 115, 115) 1px solid;
  overflow: hidden;
  background-color: white;

  & > div {
    height: 100%;
    width: 100%;
  }

  &.expanded {
    position: relative;
    z-index: 900;
  }

  &.collapsed {
    cursor: pointer;
    position: relative;
    z-index: 0;
  }
}

.navlink {
  &.visible {
    animation: fadeIn 1s;
    display: inline !important;
  }

  &.invisible {
    animation: fadeOut 1s;
    display: none !important;
  }

  .view-supplier {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
}

.supplier-content {
  position: relative;
  padding: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &.visible {
    animation: fadeIn 1s;
    display: block !important;
  }

  &.invisible {
    animation: fadeOut 1s;
    display: none !important;
  }

  .close-icon-svg {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 25px;
    width: 25px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
}

.supplier-content-header {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .supplier-content-header-logo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .supplier-image {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.supplier-image {
  max-height: 100%;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  display: inline-block;
}

.supplier-website {
  width: 50%;
  text-align: center;
  font-weight: 400;
  & > a {
    border-radius: 3px;
    transition: all 0.5s;
    padding: 5px;

    &:hover {
      background-color: $osso-colour-1;
      color: white;
    }
  }
}

.supplier-content-main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 75%;
  padding-top: 30px;

  .supplier-description {
    font-style: italic;
    width: 50%;
    font-size: initial;
    font-weight: bold;
    padding-right: 15px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
  }

  .supplier-product-carousel-container {
    width: 50%;
    padding-left: 15px;

    &,
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div,
    .supplier-product-image {
      height: 100%;
    }

    .slick-slide > div {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .supplier-product-image {
      max-height: 100%;
      height: initial !important;
      width: initial !important;
    }
  }
}
