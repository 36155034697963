@mixin box-shadow($elevation-level) {
  $blur-radius: $elevation-level * 5px;
  -webkit-box-shadow: 0px 1px $blur-radius 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px $blur-radius 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px $blur-radius 0px rgba(0, 0, 0, 0.5);
}

@each $numb in (1, 2, 3, 4, 5, 6) {
  .elevation-level-#{$numb} {
    @include box-shadow($numb);
  }
}

@mixin landing-background {
  background: rgba(0, 0, 0, 0.55);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.75) 15%,
    rgba(0, 0, 0, 0.4) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}

@keyframes fadeIn {
  0% { 
    opacity: 0;
    z-index: -1;
  }
  100% { 
    opacity: 1;
    z-index: 999;
  }
}

@keyframes fadeOut {
  0% { 
    opacity: 1;
    z-index: 999;
  }
  100% { 
    opacity: 0;
    z-index: -999;
  }
}
