@media screen and (min-width: $mobile-breakpoint) {
  html {
    font-size: 24px;
  }

  #landing-content-container {
    h1 {
      padding: 30px;
    }
  }

  #contact {
    * {
      font-size: 0.8rem;
    }
  }

  #about-content-container {
    width: 80%;
  }
}

@import "./md";
@import "./lg";
@import "./xl";
