#info {
  padding: 5vw 5vw 0px 5vw;

  .section-description {
    text-align: center;
    margin-bottom: 15px;
  }

  .highlighted {
    font-weight: bold;
    color: rgb(91, 155, 213);
  }
}
